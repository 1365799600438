<template>
    <Dialog
      :modal="true"
      :visible="visible"
      position="top"
      @update:visible="onHide()"
      :class="{'error': showError}"
    >
      <div>
        <div v-if="success">
          <h3 class="u-mb--s u-mt--l u-text-align--center">
            {{translations.smsPopupSuccessHeading}}
          </h3>
          <p class="u-text-align--center">{{translations.smsPopupSuccessText}}</p>
        </div>
        <form v-else autocomplete="nope" @submit.prevent>
          <div class="u-mb--s u-text-align--center">
            <h3 class="u-mb--s">{{translations.smsPopupLabelHeading}}</h3>
            <p>{{translations.smsPopupLabelText}}</p>
          </div>
          <div class="u-flex u-flex-align-center u-flex-justify-center u-mb--s">
            {{countryCode}}
            <input
              class="c-text-input u-ml--s"
              :class="{'c-text-input--error': showError}"
              v-model="phone"
              type="number"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="none"
              pattern="\d*"
              spellcheck="false"
            />
          </div>
          <div
            class="u-mb--s u-text-align--center p-dialog-error-message"
            v-if="showError"
          >
            {{translations.smsPopupError}}
          </div>
          <div class="u-flex u-flex-justify-center">
            <button
              class="c-button c-button--dark"
              :disabled="!phone"
              @click="sendSms()">
              {{translations.smsPopupSendButton}}
            </button>
          </div>
        </form>
      </div>
    </Dialog>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance } from 'vue'
import { mapGetters, mapState } from 'vuex'
import sendSms from '@/api/sms-api'

export default defineComponent({
  model: {
    prop: 'visible',
    event: 'update:visible'
  },
  props: {
    visible: Boolean,
    countryCode: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    heading: {
      type: String,
      required: true
    }
  },
  setup() {
    const internalInstance = getCurrentInstance()
    const onClick = (heading: string) => {
      internalInstance?.appContext.config.globalProperties.$gtm.trackEvent({
        event: 'sendRecipeDestinationFresh',
        selectedRecipe: heading
      })
    }
    return {
      onClick
    }
  },
  data() {
    return {
      phone: '',
      success: false,
      showError: false
    }
  },
  computed: {
    ...mapGetters([
      'translations'
    ]),
    ...mapState([
      'twilio'
    ])
  },
  watch: {
    phone() {
      this.showError = false
    }
  },
  methods: {
    onHide() {
      this.$emit('update:visible', false)
      this.success = false
      this.phone = ''
    },
    async sendSms() {
      try {
        this.showError = false

        const { baseUrl, accountSid, authToken } = this.twilio
        await sendSms(this.countryCode + this.phone, this.text, {
          baseUrl, accountSid, authToken
        })
        this.onClick(this.heading)
        this.success = true
        setTimeout(() => {
          this.onHide()
        }, 3000)
      } catch (err) {
        this.showError = true
      }
    }
  },
  created() {
    this.phone = ''
    this.success = false
    this.showError = false
  }
})
</script>
