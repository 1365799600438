<template>
<div class="c-recipe-card u-mb--s">
  <div @click="onClick(recipe.heading)">
    <router-link
      :to="`/recipes/${recipe.uid}${queryString}`">
      <slot/>
      <div class="c-recipe-card__image u-mb--xs">
        <img
          :alt="recipe.heading"
          :src="recipe.primaryImage && recipe.primaryImage.url"
        />
      </div>
      <span
        v-if="recipe.totalCookingTimeMinutes > 0"
        class="c-recipe-card__prep-time u-mb--xs"
      >
      {{(recipe.totalCookingTimeMinutes)>60 ? '60+' : recipe.totalCookingTimeMinutes}} min
    </span>
      <h4 class="c-recipe-card__heading">{{recipe.heading}}</h4>
    </router-link>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, PropType, getCurrentInstance } from 'vue'
import { Recipe } from '@/models/recipe'
import State from '@/store/State'
import { mapState } from 'vuex'
import getQueryString from '@/utils/get-query-string'

export default defineComponent({
  props: {
    recipe: Object as PropType<Recipe>
  },
  setup() {
    const internalInstance = getCurrentInstance()
    const onClick = (heading: string) => {
      internalInstance?.appContext.config.globalProperties.$gtm.trackEvent({
        event: 'selectRecipeDestinationFresh',
        selectedRecipe: heading
      })
    }

    const queryString = getQueryString()

    return {
      onClick,
      queryString
    }
  },
  computed: mapState({
    selectedInstance: (state) => (state as State).selectedInstance
  })
})

</script>
