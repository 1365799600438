
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import Recipe from '@/components/Recipe.vue'
import Loader from '@/components/Loader.vue'
import State from '@/store/State'

export default defineComponent({
  components: {
    Recipe,
    Loader
  },
  computed: {
    ...mapState({
      selectedInstance: (state) => (state as State).selectedInstance,
      recipes: (state) => (state as State).recipes,
      channelUid: (state) => (state as State).channelUid,
      loading: (state) => (state as State).loading
    }),
    ...mapGetters([
      'uids',
      'translations'
    ])
  },
  methods: {
    ...mapActions([
      'loadRecipes'
    ])
  },
  async created() {
    if (!this.channelUid && this.uids.length === 1) {
      const url = `/recipes/${this.uids[0]}?instance=${this.selectedInstance}`
      await this.$router.push(url)
      return
    }

    await this.loadRecipes()

    if (this.recipes.length === 1) {
      const url = `/recipes/${this.recipes[0].uid}?instance=${this.selectedInstance}`
      await this.$router.push(url)
    }
  }
})
