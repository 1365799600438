
import { defineComponent, getCurrentInstance } from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import State from '@/store/State'
import onIddle from '@/utils/on-idle'
import SmsDialog from '@/components/SmsDialog.vue'
import getQueryString from '@/utils/get-query-string'

export default defineComponent({
  components: {
    SmsDialog
  },
  data() {
    return {
      smsDialogVisible: false
    }
  },
  computed: {
    ...mapState({
      selectedInstance: (state) => (state as State).selectedInstance,
      recipe: (state) => (state as State).recipeDetails,
      recipes: (state) => (state as State).recipes,
      smsText(state) {
        const appState = state as State

        const baseUrl = appState.selectedInstance === 'arla-dk'
          ? 'arla.dk/opskrifter'
          : 'arla.se/recept'

        const url = `https://${baseUrl}/${appState.recipeDetails.slug}`

        return `${appState.recipeDetails.heading} ${url}`
      }
    }),
    ...mapGetters([
      'translations'
    ]),
    countryCode() {
      switch (this.selectedInstance) {
        case 'arla-dk':
          return '+45'
        case 'arla-se':
          return '+46'
        default:
          throw new Error('Unknown country code')
      }
    }
  },
  methods: {
    ...mapActions([
      'loadRecipeDetails'
    ]),
    openSmsDialog(heading: string) {
      this.smsDialogVisible = true
      this.onClick(heading)
    }
  },
  setup() {
    const internalInstance = getCurrentInstance()
    const onClick = (heading: string) => {
      internalInstance?.appContext.config.globalProperties.$gtm.trackEvent({
        event: 'getRecipeDestinationFresh',
        selectedRecipe: heading
      })
    }
    const queryString = getQueryString()

    return {
      onClick,
      queryString
    }
  },
  async created() {
    if (this.recipes.length < 2) {
      return
    }

    onIddle(180, () => {
      if (this.selectedInstance) {
        this.$router.push(`/recipes${this.queryString}`)
      }
    })
  }
})
