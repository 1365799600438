
import { defineComponent } from 'vue'
import State from '@/store/State'
import { mapActions, mapGetters, mapState } from 'vuex'
import getLanguage from '@/utils/get-language'
import ContentPicker from '@/components/ContentPicker.vue'

const apiUrl = process.env.VUE_APP_API_BASE_URL

export default defineComponent({
  components: {
    ContentPicker
  },
  data() {
    return {
      apiUrl,
      canShowContentPicker: true
    }
  },
  computed: {
    ...mapState({
      instances(state) {
        const { instances } = state as State

        return instances.map((instance) => ({
          name: instance
        }))
      },
      selectedInstance: (state) => (state as State).selectedInstance,
      instanceLanguage(state) {
        const instance = (state as State).selectedInstance
        return instance ? getLanguage(instance) : null
      },
      apiKey: (state) => (state as State).apiKey
    }),
    ...mapGetters([
      'uids'
    ])
  },
  methods: {
    ...mapActions([
      'setInstance',
      'setUids',
      'reset'
    ]),
    onInstanceSelected({ value }: { value: string }) {
      this.setInstance(value)
    },
    onContentPickerUpdated(event: { detail: string[] }) {
      this.setUids(event.detail)
    }
  }
})
