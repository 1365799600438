<template>
  <div v-if="recipe">
    <div class="c-header u-flex u-flex-justify-space-between">
      <router-link
        v-if="recipes.length > 1"
        :to="`/recipes${queryString}`"
        class="c-go-back"
      >
        <slot/>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             width="22px" height="35px" viewBox="-396.5 578 22 35"
             enable-background="new -396.5 578 22 35" xml:space="preserve">
<g>
  <path fill="#384241"
        stroke="#384241"
        d="M-378.8,578l-17.3,17.5l17.3,17.5l3.9-3.9l-13.4-13.6l13.4-13.6L-378.8,578z"/>
</g>
</svg>
        {{translations.goBackButton}}
      </router-link>
      <img src="~@/assets/img/arla-logo.svg" alt="" class="c-header-logo">
      <button
        @click="openSmsDialog(recipe.heading)"
        class="c-header-button u-ml--a"
      >
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 24">
<path d="M14.25,8.25h1.5a1.5,1.5,0,0,1,1.5,1.5v12a1.5,1.5,0,0,1-1.5,
1.5H2.25a1.5,1.5,0,0,1-1.5-1.5v-12a1.5,1.5,0,0,1,1.5-1.5h1.5"
fill="none" stroke-linecap="round" stroke-linejoin="round"
stroke-width="1.5"/>
<line x1="9" y1="0.75" x2="9" y2="11.25" fill="none" stroke-linecap="round"
stroke-linejoin="round" stroke-width="1.5"/>
<polyline points="5.25 4.5 9 0.75 12.75 4.5" fill="none" stroke-linecap="round"
stroke-linejoin="round" stroke-width="1.5"/>
</svg>
        {{translations.sendSmsButton}}
      </button>
    </div>
    <div class="c-recipe">
      <div class="c-recipe__hero">
        <div class="o-content-box c-recipe__details">
          <strong class="c-heading-label u-mb--s">{{translations.headingLabel}}</strong>
          <h1 class="u-text-break u-mb--s u-mb--m@mobile">{{ recipe.heading }}</h1>
          <div class="u-flex u-flex-wrap u-flex-align-center u-mb--m u-mb--m@mobile">
            <div class="u-flex u-flex-align-center u-mr--a u-mt--s u-mt--s@mobile">
              <svg
                v-if="recipe.totalCookingTimeMinutes > 0"
                class="c-icon-24 c-icon-stroke-black u-mr--xs"
              >
                <use xlink:href="~@/assets/img/sprite.svg#icon-stopwatch"></use>
              </svg>
              <span
                v-if="recipe.totalCookingTimeMinutes > 0"
                class="c-label--s u-mr--s"
              >
                {{(recipe.totalCookingTimeMinutes)>60 ? '60+' : recipe.totalCookingTimeMinutes}} min
              </span>
              <template
                v-for="tag in recipe.tags"
                :key="tag.name"
              >
                <span
                  v-if="tag.groupUid == 'tdb:8869'"
                  class="c-tag u-mr--xs"
                >{{ tag.name }}</span>
              </template>
            </div>
          </div>
          <div class="c-recipe__description u-mt--s@mobile">
            <p>{{ recipe.preamble }}</p>
          </div>
        </div>
        <div class="c-recipe__image">
          <picture class="c-recipe__image-ratio-holder">
            <img
              :src="recipe.primaryImage && recipe.primaryImage.url"
              :alt="recipe.heading"
            />
          </picture>
        </div>
      </div>
      <div class="c-recipe__content">
        <div class="o-content-box c-recipe__ingredients">
          <div class="c-recipe__ingredients-inner">
            <h3 class="u-mb--m">
              {{translations.ingredients}}
              for
              {{ recipe.yield?.amount?.value }}
              {{translations.persons}}
            </h3>
            <div
              v-for="(ingredientParent, index) in recipe.ingredientGroups"
              :key="index"
              class="u-mt--m"
            >
              <h5>{{ ingredientParent.heading }}</h5>
              <table>
                <tr
                  v-for="(item, index) in ingredientParent.ingredients"
                  :key="index"
                >
                  <th class="u-width-70">
    {{item.prefix}}
    {{(item.ingredient.isMassNoun) ? item.ingredient.namePlural : item.ingredient.nameSingular}}
    {{item.postfix }}
                  </th>
                  <td class="u-text-align--right">
    {{(item.amount.value == 0) ? '' : item.amount.value }}
    {{(item.ingredient.isMassNoun) ? item.amount.unit.namePlural : item.amount.unit.nameSingular}}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="o-content-box c-recipe__instructions">
          <div class="c-recipe__instructions-inner u-flex">
            <div class="c-recipe__how-to">
              <div class="c-recipe__instructions-steps">
                <h3 class="c-recipe__instructions-label u-flex u-mb--m">
                  <svg class="u-mr--s u-hidden-print c-icon-32 c-icon-stroke-black" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.02 21">
                    <g>
                      <path d="M3.76,11.25h16.5v8a1,1,0,0,1-1,1H4.76a1,1,0,0,1-1-1v-8Z"
                            fill="none"
                            stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                      <path d="M3.76,12a6,6,0,0,1,3-11.2c4.5,0,5.25,4.5,9,4.5"
                            fill="none"
                            stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                      <path d="M20.26,12a6,6,0,0,0-3-11.2C14.72.75,13.37,2.18,12,3.43"
                            fill="none"
                            stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                      <line x1="8.26" y1="15.75" x2="8.26" y2="17.25"
                            fill="none"
                            stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                      <line x1="12.01" y1="15.75" x2="12.01" y2="17.25"
                            fill="none"
                            stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                      <line x1="15.76" y1="15.75" x2="15.76" y2="17.25"
                            fill="none"
                            stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    </g>
                  </svg>
                  {{translations.cookInstructions}}
                </h3>
                <template
                  v-for="(step, index) in recipe.cookingInstructions"
                  :key="index"
                >
                  <h5
                    v-if="step.heading"
                    class="u-ml--m"
                  >
                    <span class="u-ml--s">{{step.heading}}</span>
                  </h5>
                  <ul
                    class="u-bare-list u-ml--m"
                    :class="{'c-recipe__instructions-steps-multi': step.text.includes('\n')}"
                  >
                    <li
                      v-for="(text, index) in step.text.split(/[\r\n]+/)"
                      :key="index"
                      class="c-recipe__instructions-step u-ml--s"
                    >
                      <span>{{text}}</span>
                    </li>
                  </ul>
                </template>
                <strong class="c-recipe__instructions-label u-flex u-font-size-h3 u-m--0">
                  <svg class="c-icon-32 c-icon-stroke-black u-mr--s u-hidden-print" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <g>
                      <path d="M17,.75c1.44,2.87-2.9,3.88-.75,6.75"
                            fill="none" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="1.5"/>
                      <path d="M12.53.75C14,3.62,9.63,4.63,11.78,7.5"
                            fill="none" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="1.5"/>
                      <path d="M8,.75c1.44,2.87-2.9,3.88-.75,6.75"
                            fill="none" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="1.5"/>
                      <ellipse cx="12" cy="12.75" rx="11.25" ry="3"
                               fill="none" stroke-linecap="round"
                               stroke-linejoin="round" stroke-width="1.5"/>
                      <path d="M23.25,12.75c0,5.8-5,10.5-11.25,10.5S.75,18.55.75,12.75"
                            fill="none" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="1.5"/>
                    </g>
                  </svg>
                  {{translations.enjoyYourMeal}}
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SmsDialog
      v-model:visible="smsDialogVisible"
      :countryCode="countryCode"
      :text="smsText"
      :heading="recipe.heading"
    >
    </SmsDialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance } from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import State from '@/store/State'
import onIddle from '@/utils/on-idle'
import SmsDialog from '@/components/SmsDialog.vue'
import getQueryString from '@/utils/get-query-string'

export default defineComponent({
  components: {
    SmsDialog
  },
  data() {
    return {
      smsDialogVisible: false
    }
  },
  computed: {
    ...mapState({
      selectedInstance: (state) => (state as State).selectedInstance,
      recipe: (state) => (state as State).recipeDetails,
      recipes: (state) => (state as State).recipes,
      smsText(state) {
        const appState = state as State

        const baseUrl = appState.selectedInstance === 'arla-dk'
          ? 'arla.dk/opskrifter'
          : 'arla.se/recept'

        const url = `https://${baseUrl}/${appState.recipeDetails.slug}`

        return `${appState.recipeDetails.heading} ${url}`
      }
    }),
    ...mapGetters([
      'translations'
    ]),
    countryCode() {
      switch (this.selectedInstance) {
        case 'arla-dk':
          return '+45'
        case 'arla-se':
          return '+46'
        default:
          throw new Error('Unknown country code')
      }
    }
  },
  methods: {
    ...mapActions([
      'loadRecipeDetails'
    ]),
    openSmsDialog(heading: string) {
      this.smsDialogVisible = true
      this.onClick(heading)
    }
  },
  setup() {
    const internalInstance = getCurrentInstance()
    const onClick = (heading: string) => {
      internalInstance?.appContext.config.globalProperties.$gtm.trackEvent({
        event: 'getRecipeDestinationFresh',
        selectedRecipe: heading
      })
    }
    const queryString = getQueryString()

    return {
      onClick,
      queryString
    }
  },
  async created() {
    if (this.recipes.length < 2) {
      return
    }

    onIddle(180, () => {
      if (this.selectedInstance) {
        this.$router.push(`/recipes${this.queryString}`)
      }
    })
  }
})
</script>
