import { Recipe } from '@/models/recipe'
import getLanguage from '@/utils/get-language'
import { RecipeDetails } from '@/models/recipe-details'

function getRecipesQuery(instance: string, uids: string[], channelUid: string) {
  const language = getLanguage(instance)
  const channelsFilter = `channels: { any: { eq: "${channelUid}" } }`
  const uidsFilter = `uid: { in: "${uids.join(',')}" }`

  const filter = `{
   instance: {
      eq: "${instance}"
    },
    type: {
      eq: "Recipe"
    },
    published: true,
    ${channelUid ? channelsFilter : uidsFilter}
}`

  return `
query GetContent{
    content(language: "${language}", take: 1000 filter: ${filter}) {
        hits {
            content {
                heading,
                primaryImage {
                  url
                },
                uid,
                ... on Recipe {
                  totalCookingTimeMinutes
                }
            }
        }
    }
}`
}

function getRecipeQuery(instance: string, uid: string) {
  const language = getLanguage(instance)

  return `
query GetContent{
    content(language: "${language}", filter: {
        instance: {
            eq: "${instance}"
        },
        type: {
            eq: "Recipe"
        },
        published: true,
        uid: {
          eq: "${uid}"
        }
    }) {
        hits {
            content {
                ...contentFields
            }
        }
    }
}
fragment recipe on Recipe {
  totalCookingTimeMinutes,
  ingredientGroups {
    heading
    ingredients {
      ingredient {
        isMassNoun
        namePlural
        nameSingular
      }
      amount {
        unit {
          namePlural
          nameSingular
        }
        value
      }
      postfix
      prefix
    }
  },
  cookingInstructions {
    heading
    text
  },
  yield {
    amount {
      value
    }
  }
}
fragment contentFields on Content {
  heading,
  preamble,
  slug,
  primaryImage {
    url
  },
  tags {
    groupUid
    name
  },
  uid,
  ...recipe
}`
}

export default {
  async getRecipes(instance: string, uids: string[], channelUid: string,
    apiKey: string): Promise<Recipe[]> {
    if (!channelUid && !uids.length) {
      return []
    }

    const headers = {
      'Ocp-Apim-Subscription-Key': apiKey,
      'Content-Type': 'application/json'
    } as Record<string, string>

    const body = {
      query: getRecipesQuery(instance, uids, channelUid)
    }

    const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}`, {
      headers,
      method: 'POST',
      body: JSON.stringify(body)
    })

    const result = await response.json()
    return result.data.content.hits.map((hit: { content: Recipe }) => hit.content)
  },
  async getRecipeDetails(instance: string, uid: string,
    apiKey: string): Promise<RecipeDetails | null> {
    const headers = {
      'Ocp-Apim-Subscription-Key': apiKey,
      'Content-Type': 'application/json'
    } as Record<string, string>

    const body = {
      query: getRecipeQuery(instance, uid)
    }

    const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}`, {
      headers,
      method: 'POST',
      body: JSON.stringify(body)
    })

    const result = await response.json()
    return result.data.content.hits.map((hit: { content: RecipeDetails }) => hit.content)[0]
  }
}
