
import { defineComponent, getCurrentInstance } from 'vue'
import { mapGetters, mapState } from 'vuex'
import sendSms from '@/api/sms-api'

export default defineComponent({
  model: {
    prop: 'visible',
    event: 'update:visible'
  },
  props: {
    visible: Boolean,
    countryCode: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    heading: {
      type: String,
      required: true
    }
  },
  setup() {
    const internalInstance = getCurrentInstance()
    const onClick = (heading: string) => {
      internalInstance?.appContext.config.globalProperties.$gtm.trackEvent({
        event: 'sendRecipeDestinationFresh',
        selectedRecipe: heading
      })
    }
    return {
      onClick
    }
  },
  data() {
    return {
      phone: '',
      success: false,
      showError: false
    }
  },
  computed: {
    ...mapGetters([
      'translations'
    ]),
    ...mapState([
      'twilio'
    ])
  },
  watch: {
    phone() {
      this.showError = false
    }
  },
  methods: {
    onHide() {
      this.$emit('update:visible', false)
      this.success = false
      this.phone = ''
    },
    async sendSms() {
      try {
        this.showError = false

        const { baseUrl, accountSid, authToken } = this.twilio
        await sendSms(this.countryCode + this.phone, this.text, {
          baseUrl, accountSid, authToken
        })
        this.onClick(this.heading)
        this.success = true
        setTimeout(() => {
          this.onHide()
        }, 3000)
      } catch (err) {
        this.showError = true
      }
    }
  },
  created() {
    this.phone = ''
    this.success = false
    this.showError = false
  }
})
