import { createApp } from 'vue'
import App from '@/App.vue'
import { createGtm } from 'vue-gtm'
import store from '@/store'
import router from '@/router'
import Dropdown from 'primevue/dropdown'
import Dialog from 'primevue/dialog'
import InputMask from 'primevue/inputmask'

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import './assets/styles/index.scss'
import { applyPolyfills, defineCustomElements } from '@arla-ecosystem/webcomponents/loader'

applyPolyfills()
  .then(() => defineCustomElements())

const app = createApp(App)

app.config.warnHandler = (msg, instance, info) => {
  if (msg === 'Failed to resolve component: arla-content-picker') {
    return
  }

  console.warn(msg, instance, info)
}

app.use(store)
  .use(router)
  .use(createGtm({
    id: 'GTM-KBPZ34',
    defer: false,
    compatibility: false,
    enabled: true,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false
  }))
  .component('Dropdown', Dropdown)
  .component('Dialog', Dialog)
  .component('InputMask', InputMask)
  .mount('#destination-fresh')
