<template>
  <arla-content-picker
    v-if="visible"
    :type="type"
    :instance="instance"
    :language="language"
    :api-url="apiUrl"
    :api-key="apiKey"
    :selected-content="uids.join(',')">
  </arla-content-picker>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import delay from '@/utils/delay'

export default defineComponent({
  props: {
    type: String,
    instance: String,
    language: String,
    apiUrl: String,
    apiKey: String,
    uids: Array as PropType<string[]>
  },
  data() {
    return {
      visible: true
    }
  },
  methods: {
    async rerenderContentPicker() {
      this.visible = false
      await delay()
      this.visible = true
    },
    async subscribeToContentPickerEvents() {
      // arla web components does not support normal event binding
      const picker = await this.getContentPicker()

      if (!picker) {
        return
      }

      picker.addEventListener('contentPickerUpdated', (event: Event) => {
        const pickerEvent = event as unknown as { detail: string[] }
        this.$emit('content-picker-updated', pickerEvent)
      })
    },
    async getContentPicker(): Promise<HTMLElement> {
      const picker = document.querySelector('arla-content-picker')

      if (picker) {
        return picker
      }

      console.log('cannot found picker')

      await delay(100)

      return this.getContentPicker()
    }
  },
  watch: {
    async instance(current, old) {
      if (current === old) {
        return
      }

      await this.rerenderContentPicker()

      if (current) {
        this.subscribeToContentPickerEvents()
      }
    }
  },
  mounted() {
    this.subscribeToContentPickerEvents()
  }
})
</script>
