import { createStore } from 'vuex'
import { Recipe } from '@/models/recipe'
import recipeApi from '@/api/recipes-api'
import { RecipeDetails } from '@/models/recipe-details'
import State from '@/store/State'
import translations from '@/translations/recipe-translations'
import getQueryString from '@/utils/get-query-string'

function getStateFromLocalStorage() {
  const json = localStorage.getItem('state')
  return json && JSON.parse(json)
}

function setStateToLocalStorage(state: State) {
  const json = JSON.stringify(state)
  localStorage.setItem('state', json)
}

const defaultState = {
  instances: ['arla-dk', 'arla-se'],
  selectedInstance: '',
  instancesUids: {},
  uid: null,
  recipes: [],
  recipeDetails: null,
  loading: false,
  translations,
  channelUid: null
}

const setPredefinedConfiguration = () => {
  const queryString = getQueryString()
  const searchParams = new URLSearchParams(queryString)
  const selectedInstance = searchParams.get('instance')
  const recipes = searchParams.get('recipes')
  const twilioUrl = searchParams.get('twilio-url') || process.env.VUE_APP_TWILIO_BASE_URL
  const twilioSid = searchParams.get('twilio-sid') || process.env.VUE_APP_TWILIO_ACCOUNT_SID
  const twilioAuthToken = searchParams.get('twilio-auth')
  if (!selectedInstance) {
    return
  }

  const configurationState = {
    twilio: {
      baseUrl: twilioUrl,
      accountSid: twilioSid,
      authToken: twilioAuthToken
    },
    ...defaultState
  }
  if (selectedInstance && recipes) {
    configurationState.selectedInstance = selectedInstance
    configurationState.instancesUids = {
      [selectedInstance]: recipes.split(',')
    }
  }
  const json = JSON.stringify(configurationState)
  localStorage.setItem('state', json)
}

setPredefinedConfiguration()

const localStorageState = getStateFromLocalStorage()

const initialState = localStorageState
  ? {
    ...localStorageState,
    translations
  }
  : defaultState

const store = createStore<State>({
  state: { ...initialState },
  getters: {
    uids(state: State): string[] {
      if (!state.selectedInstance) {
        return []
      }

      return state.instancesUids[state.selectedInstance] || []
    },
    translations(state: State): { [key: string]: string } {
      if (!state.selectedInstance) {
        return {}
      }

      return state.translations[state.selectedInstance] || {}
    },
    twilio(state: State) {
      return state.twilio
    }
  },
  mutations: {
    setInstance(state: State, instance: string) {
      state.selectedInstance = instance
    },
    setUid(state: State, uid: string) {
      state.uid = uid
    },
    setUids(state: State, uids: string[]) {
      if (!state.selectedInstance) {
        return
      }

      state.instancesUids[state.selectedInstance] = uids
    },
    setChannelUid(state: State, channelUid: string) {
      state.channelUid = channelUid
    },
    setApiKey(state: State, apiKey) {
      state.apiKey = apiKey
    },
    loadRecipes(state: State, recipes: Recipe[]) {
      state.recipes = recipes
    },
    loadRecipeDetails(state: State, recipeDetails: RecipeDetails) {
      state.recipeDetails = recipeDetails
    },
    showLoader(state: State) {
      state.loading = true
    },
    hideLoader(state: State) {
      state.loading = false
    }
  },
  actions: {
    reset() {
      localStorage.clear()
      window.location.reload()
    },
    setInstance({ commit }, instance: string) {
      commit('setInstance', instance)
    },
    async setUid({ commit, dispatch }, uid: string) {
      await commit('setUid', uid)
      await dispatch('loadRecipeDetails')
    },
    async setUids({ commit, dispatch }, uids: string[]) {
      await commit('setUids', uids)
      await dispatch('loadRecipes')
    },
    async setChannelUid({ commit, dispatch }, channelUid: string) {
      await commit('setChannelUid', channelUid)
      await dispatch('loadRecipes')
    },
    async setApiKey({ commit }, apiKey: string) {
      await commit('setApiKey', apiKey)
    },
    async loadRecipes({ commit, state, getters }) {
      commit('showLoader')

      const { uids }: { uids: string[] } = getters
      const recipes = await recipeApi.getRecipes(state.selectedInstance, uids, state.channelUid,
        state.apiKey)

      if (state.channelUid) {
        commit('loadRecipes', recipes)
      } else {
        const orderedRecipes = uids
          .map((uid) => recipes.find((recipe) => recipe.uid === uid))
          .filter((recipe) => recipe)

        commit('loadRecipes', orderedRecipes)
      }

      commit('hideLoader')
    },
    async loadRecipeDetails({ commit, state }) {
      commit('showLoader')

      const recipeDetails = state.uid
        ? await recipeApi.getRecipeDetails(state.selectedInstance, state.uid, state.apiKey)
        : null

      commit('loadRecipeDetails', recipeDetails)

      commit('hideLoader')
    }
  }
})

store.subscribe((payload, state) => {
  setStateToLocalStorage(state)
})

export default store
