interface TwilioConfig {
  baseUrl: string;
  accountSid: string;
  authToken: string;
}

export default async function sendSms(phone: string, text: string, config: TwilioConfig) {
  const { baseUrl, accountSid, authToken } = config
  const url = `${baseUrl}/Accounts/${accountSid}/Messages.json`

  const body = new FormData()
  body.append('From', 'Arla')
  body.append('To', phone)
  body.append('Body', `${text}?utm_medium=sms&utm_campaign=destination-fresh&utm_source=retail`)

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${btoa(`${accountSid}:${authToken}`)}`
    },
    body
  })

  if (response.ok) {
    return response
  }
  throw new Error(response.statusText)
}
