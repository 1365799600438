import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import RecipeDetails from '@/views/RecipeDetails.vue'
import store from '@/store'
import Recipes from '@/views/Recipes.vue'
import Configuration from '@/views/Configuration.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: () => {
      const instance = store.state.selectedInstance
      return {
        path: instance ? `/recipes?instance=${instance}` : '/configuration'
      }
    }
  },
  {
    path: '/configuration',
    name: 'Configuration',
    component: Configuration
  },
  {
    path: '/recipes',
    name: 'Recipes',
    component: Recipes
  },
  {
    path: '/recipes/:uid',
    component: RecipeDetails
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  if (to.query.apiKey) {
    await store.dispatch('setApiKey', to.query.apiKey)
  }

  if (to.query.instance) {
    await store.dispatch('setInstance', to.query.instance)
  }

  await store.dispatch('setUid', to.params.uid)
  await store.dispatch('setChannelUid', to.query.channelUid)
})

export default router
