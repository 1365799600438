<template>
  <div
  >
    <router-view/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue/'
import store from '@/store'

export default defineComponent({
  store
})

</script>
