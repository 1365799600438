export default function onIdle(seconds: number, callback: Function) {
  if (!seconds || !callback) {
    return
  }

  let timeout: number

  const resetTimer = () => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      callback()
    }, seconds * 1000)
  }

  resetTimer()

  document.onmousemove = resetTimer
  document.onkeypress = resetTimer
  document.onscroll = resetTimer
  document.ontouchstart = resetTimer
  document.ontouchend = resetTimer
  document.ontouchmove = resetTimer
  document.ontouchcancel = resetTimer
}
