<template>
  <div class="o-content-box">
    <div class="u-mb--s">
      <button class="c-button" @click="reset()">
        Reset
      </button>
    </div>
    <br>
    <Dropdown
      :model-value="selectedInstance"
      option-value="name"
      optionLabel="name"
      :options="instances"
      placeholder="Select instance"
      @change="onInstanceSelected"
      class="u-mb--m"
    >
    </Dropdown>
    <br/>
    <ContentPicker
      v-if="selectedInstance"
      type="Recipe"
      :instance="selectedInstance"
      :language="instanceLanguage"
      :api-url="apiUrl"
      :api-key="apiKey"
      :uids="uids"
      @content-picker-updated="onContentPickerUpdated">
    </ContentPicker>
    <br/>
    <router-link
      v-if="selectedInstance && uids.length"
      :to="`/recipes?instance=${selectedInstance}`">
      <button class="c-button c-button--dark">Recipes</button>
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import State from '@/store/State'
import { mapActions, mapGetters, mapState } from 'vuex'
import getLanguage from '@/utils/get-language'
import ContentPicker from '@/components/ContentPicker.vue'

const apiUrl = process.env.VUE_APP_API_BASE_URL

export default defineComponent({
  components: {
    ContentPicker
  },
  data() {
    return {
      apiUrl,
      canShowContentPicker: true
    }
  },
  computed: {
    ...mapState({
      instances(state) {
        const { instances } = state as State

        return instances.map((instance) => ({
          name: instance
        }))
      },
      selectedInstance: (state) => (state as State).selectedInstance,
      instanceLanguage(state) {
        const instance = (state as State).selectedInstance
        return instance ? getLanguage(instance) : null
      },
      apiKey: (state) => (state as State).apiKey
    }),
    ...mapGetters([
      'uids'
    ])
  },
  methods: {
    ...mapActions([
      'setInstance',
      'setUids',
      'reset'
    ]),
    onInstanceSelected({ value }: { value: string }) {
      this.setInstance(value)
    },
    onContentPickerUpdated(event: { detail: string[] }) {
      this.setUids(event.detail)
    }
  }
})
</script>
