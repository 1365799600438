export default function getLanguage(instance: string) {
  switch (instance) {
    case 'arla-dk':
      return 'da'
    case 'arla-se':
      return 'sv'
    default:
      return null
  }
}
