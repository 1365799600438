<template>
  <div class="c-hero u-flex u-flex-justify-center u-flex-align-center u-flex-direction-column">
    <img src="~@/assets/img/hero.jpg" alt="" class="c-hero-bg">
    <div class="c-hero__content">
      <img src="~@/assets/img/arla-logo.svg" alt="" class="u-ml--a u-mr--a">
      <h1 class="u-m--0">{{translations.heroText}}</h1>
    </div>
  </div>
  <h3 class="recipe-overview__description">{{translations.description}}</h3>
  <Loader
    v-if="loading"
    class="c-loader--large u-flex u-ml--a u-mr--a"
    color="medium-gray"
  />
  <div
    v-if="!loading" class="c-recipe-overview u-flex u-flex-wrap"
    :class="{'wide-recipes' : recipes.length === 2}"
  >
    <recipe
      v-for="recipe in recipes"
      :key="recipe.id"
      :recipe="recipe"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import Recipe from '@/components/Recipe.vue'
import Loader from '@/components/Loader.vue'
import State from '@/store/State'

export default defineComponent({
  components: {
    Recipe,
    Loader
  },
  computed: {
    ...mapState({
      selectedInstance: (state) => (state as State).selectedInstance,
      recipes: (state) => (state as State).recipes,
      channelUid: (state) => (state as State).channelUid,
      loading: (state) => (state as State).loading
    }),
    ...mapGetters([
      'uids',
      'translations'
    ])
  },
  methods: {
    ...mapActions([
      'loadRecipes'
    ])
  },
  async created() {
    if (!this.channelUid && this.uids.length === 1) {
      const url = `/recipes/${this.uids[0]}?instance=${this.selectedInstance}`
      await this.$router.push(url)
      return
    }

    await this.loadRecipes()

    if (this.recipes.length === 1) {
      const url = `/recipes/${this.recipes[0].uid}?instance=${this.selectedInstance}`
      await this.$router.push(url)
    }
  }
})
</script>
