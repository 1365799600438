
import { defineComponent, PropType, getCurrentInstance } from 'vue'
import { Recipe } from '@/models/recipe'
import State from '@/store/State'
import { mapState } from 'vuex'
import getQueryString from '@/utils/get-query-string'

export default defineComponent({
  props: {
    recipe: Object as PropType<Recipe>
  },
  setup() {
    const internalInstance = getCurrentInstance()
    const onClick = (heading: string) => {
      internalInstance?.appContext.config.globalProperties.$gtm.trackEvent({
        event: 'selectRecipeDestinationFresh',
        selectedRecipe: heading
      })
    }

    const queryString = getQueryString()

    return {
      onClick,
      queryString
    }
  },
  computed: mapState({
    selectedInstance: (state) => (state as State).selectedInstance
  })
})

