export default {
  'arla-dk': {
    heroText: 'Nem aftensmad',
    description: 'Vælg en opskrift og find alle de nødvendige ingredienser lige her.',
    headingLabel: 'Opskrift',
    ingredients: 'Ingredienser',
    persons: 'personer',
    cookInstructions: 'Sådan gør du...',
    enjoyYourMeal: 'Velbekomme!',
    goBackButton: 'Opskrifter',
    sendSmsButton: 'Få opskriften',
    smsPopupLabelHeading: 'Indtast dit mobilnummer',
    smsPopupLabelText: 'Så modtager du en SMS (og intet andet) med et link til opskriften.',
    smsPopupError: 'Der gik noget galt. Giv det et forsøg mere og kontakt personalet hvis du fortsat oplever fejl. Tak!',
    smsPopupSuccessHeading: 'Opskriften er sendt!',
    smsPopupSuccessText: 'God madlavning!',
    smsPopupSendButton: 'Send opskrift'
  },
  'arla-se': {
    heroText: 'Enkel kvällsmat',
    description: 'Välj recpet och se vad du behöver handla.',
    headingLabel: 'Recept',
    ingredients: 'Ingredienser',
    persons: 'portioner',
    cookInstructions: 'Gör så här',
    enjoyYourMeal: 'God aptit',
    goBackButton: 'Recept',
    sendSmsButton: 'Få receptet',
    smsPopupLabelHeading: 'Skriv in ditt mobilnummer',
    smsPopupLabelText: 'Då får du ett SMS (och inget annat) med en länk till receptet.',
    smsPopupError: 'Något gick fel. Ge det ett nytt försök och kontakta personalen om du fortsätter att uppleva fel. Tack!',
    smsPopupSuccessHeading: 'Receptet har skickats!',
    smsPopupSuccessText: 'God matlagning!',
    smsPopupSendButton: 'Skickar receptet'
  }
}
